import React, { useState, useContext } from 'react';

import './Auth.scss';

import { AuthContext } from '../../shared/context/auth-context';
import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Input from '../../shared/components/FormElements/Input/Input';
import Button from '../../shared/components/FormElements/Button/Button';
import Card from '../../shared/components/UIElements/Card/Card';
import ErrorModal from '../../shared/components/UIElements/ErrorModal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner/LoadingSpinner';
import ImageUpload from '../../shared/components/FormElements/ImageUpload/ImageUpload';
import { VALIDATOR_REQUIRE, VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from '../../shared/util/validators';

const Auth = () => {

  const { login } = useContext(AuthContext)
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm({
    email: {
      value: '',
      isValid: false
    },
    password: {
      value: '',
      isValid: false
    }
  }, false)

  const authSubmitHandler = async e => {
    e.preventDefault();

    if (isLoginMode) {
      try {
        // sendRequest(url, method, body, headers)
        const resData = await sendRequest(
          `${ process.env.REACT_APP_BACKEND_URL }/api/users/login`,
          'POST',
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value
          }),
          { 'Content-Type': 'application/json' }
        );
        login(resData.userId, resData.token);

      } catch (err) { }

    } else {
      try {
        const formData = new FormData();
        formData.append('name', formState.inputs.name.value);
        formData.append('email', formState.inputs.email.value);
        formData.append('password', formState.inputs.password.value);
        formData.append('image', formState.inputs.image.value);

        // sendRequest(url, method, body, headers)
        const resData = await sendRequest(
          `${ process.env.REACT_APP_BACKEND_URL }/api/users/signup`,
          'POST',
          formData
        );
        login(resData.userId, resData.token);

      } catch (err) { }
    }
  }

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData({
        ...formState.inputs,
        name: undefined,
        image: undefined
      },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      )
    } else {
      setFormData({
        ...formState.inputs,
        name: {
          value: '',
          isValid: false
        },
        image: {
          value: null,
          isValid: false
        }
      }, false)
    }
    setIsLoginMode(prevMode => !prevMode)
  }

  return (
    <React.Fragment>
      <ErrorModal error={ error } onClear={ clearError } />
      <Card className="authentication">
        { isLoading && <LoadingSpinner asOverlay /> }
        <h2 className="authentication__header">Sign
          { isLoginMode ? '-In' : '-Up' }
        </h2>
        <form onSubmit={ authSubmitHandler }>
          { !isLoginMode &&
            <Input
              id="name"
              element="input"
              type="text"
              label="Your Name"
              onInput={ inputHandler }
              validators={ [VALIDATOR_REQUIRE()] }
              errorText="Please enter your name">
            </Input> }
          { !isLoginMode &&
            <ImageUpload
              center
              id="image"
              onInput={ inputHandler }
              errorText="Please provide an image"
            /> }
          <Input
            id="email"
            element="input"
            type="email"
            label="E-Mail"
            onInput={ inputHandler }
            validators={ [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()] }
            errorText="Please enter a valid email address" />
          <Input
            id="password"
            element="input"
            type="password"
            label="Password"
            onInput={ inputHandler }
            validators={ [VALIDATOR_MINLENGTH(6)] }
            errorText="Password must be at least 6 characters" />

          <Button type="submit" disabled={ !formState.isValid }>
            { isLoginMode ? 'LOGIN' : 'SIGNUP' }
          </Button>
        </form>

        <Button size="switch" onClick={ switchModeHandler }>SWITCH TO { isLoginMode ? 'SIGNUP' : 'LOGIN' }</Button>
      </Card>
    </React.Fragment>
  );
};

export default Auth;