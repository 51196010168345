import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useHttpClient } from '../../shared/hooks/http-hook';
import PlaceList from '../components/PlaceList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner/LoadingSpinner';

const UserPlaces = () => {

  const [loadedPlaces, setLoadedPlaces] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const userId = useParams().userId;

  useEffect(() => {

    const fetchPlaces = async () => {
      try {
        // sendRequest(url, method, body, headers)
        const resData = await sendRequest(`${ process.env.REACT_APP_BACKEND_URL }/api/places/user/${ userId }`)
        setLoadedPlaces(resData.places);
      } catch (err) { }
    };
    fetchPlaces();
  }, [sendRequest, userId])

  const placeDeletedHandler = (deletedPlaceId) => {
    setLoadedPlaces(loadedPlaces => loadedPlaces.filter(place => place.id !== deletedPlaceId))
  }

  return (
    <React.Fragment>
      <ErrorModal error={ error } onClear={ clearError } />
      { isLoading && <div className="center">
        <LoadingSpinner />
      </div> }
      { !isLoading && loadedPlaces && <PlaceList items={ loadedPlaces } onDeletePlace={ placeDeletedHandler } /> }
    </React.Fragment>
  )
};

export default UserPlaces;