import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './MainNavigation.scss';

import logo from '../../../assets/images/app-logo.svg';
import MainHeader from './MainHeader';
import NavLinks from './NavLinks';
import SideDrawer from './SideDrawer';
import Backdrop from '../UIElements/Backdrop/Backdrop';

const MainNavigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true)
  }

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false)
  }

  return (
    <React.Fragment>
      { drawerIsOpen && <Backdrop onClick={ closeDrawerHandler } /> }
      <SideDrawer
        show={ drawerIsOpen }
        onClick={ closeDrawerHandler }>
        <nav className="main-navigation__drawer-nav">
          <NavLinks />
        </nav>
      </SideDrawer>
      <MainHeader>
        <div className="main-navigation__logo">
          <Link to="/">
            <img src={ logo } alt="logo" />
          </Link>
        </div>
        <nav className="main-navigation__header-nav">
          <NavLinks />
        </nav>
        <button
          className="main-navigation__menu-btn"
          onClick={ openDrawerHandler }>
          <span />
          <span />
          <span />
        </button>
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;