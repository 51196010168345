import React, { useRef, useEffect } from 'react';

import './Map.scss';

const Map = props => {

  const mapRef = useRef()

  useEffect(() => {

    // Map creation
    const map = new window.google.maps.Map(mapRef.current, {
      center: props.center,
      zoom: props.zoom
    });

    new window.google.maps.Marker({ position: props.center, map: map })
  }, [props.center, props.zoom])

  return (
    <div
      className={ `map ${ props.className }` }
      style={ props.style }
      ref={ mapRef }>
    </div>
  );
};

export default Map;