import React, { useContext } from 'react';

import './NavLinks.scss';

import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';

const NavLinks = props => {

  const { isLoggedIn, userId, logout } = useContext(AuthContext)

  return (
    <ul className="nav-links">
      <li>
        <NavLink exact to="/">ALL USERS</NavLink>
      </li>
      { isLoggedIn && <li>
        <NavLink to={ `/${ userId }/places` }>MY PLACES</NavLink>
      </li> }
      { isLoggedIn && <li>
        <NavLink to="/places/new">ADD PLACE</NavLink>
      </li> }
      { !isLoggedIn && <li>
        <NavLink to="/auth">AUTH</NavLink>
      </li> }

      { isLoggedIn && <li>
        <button onClick={ logout }>LOGOUT</button>
      </li> }
    </ul>
  );
};

export default NavLinks;