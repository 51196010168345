import React, { useEffect, useState } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import UsersList from '../components/UsersList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner/LoadingSpinner';

const Users = () => {

  const [users, setUsers] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {

    // async operation inside a hook
    const fetchUsers = async () => {
      try {
        const resData = await sendRequest(`${ process.env.REACT_APP_BACKEND_URL }/api/users`);
        setUsers(resData.users);

      } catch (err) { }
    }
    fetchUsers();
  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorModal error={ error } onClear={ clearError } />
      { isLoading && (
        <div className='center'>
          <LoadingSpinner />
        </div>
      ) }
      { !isLoading && users && <UsersList items={ users } /> }
    </React.Fragment>
  )
};

export default Users;