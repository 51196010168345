import React, { useReducer, useEffect } from 'react';

import './Input.scss';

import { validate } from '../../../util/validators';

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      }
    }
    default:
      return state;
  }
};

const Input = props => {

  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isValid: props.initialValid || false,
    isTouched: false
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid)
  }, [id, onInput, value, isValid])

  const changeHandler = e => {
    dispatch({
      type: 'CHANGE',
      val: e.target.value,
      validators: props.validators
    });
  };

  const touchHandler = () => {
    dispatch({
      type: 'TOUCH'
    });
  };

  let element;

  switch (props.element) {
    case 'input': element = (
      <input
        id={ props.id }
        type={ props.type }
        value={ inputState.value }
        placeholder={ props.placeholder }
        onChange={ changeHandler }
        onBlur={ touchHandler } />)
      break;
    case 'textarea': element = (
      <textarea
        id={ props.id }
        rows={ props.rows || 3 }
        value={ inputState.value }
        placeholder={ props.placeholder }
        onChange={ changeHandler }
        onBlur={ touchHandler } />)
      break;
    default: throw new Error('invalid type of input element')
  }

  return (
    <div
      className={ `form-control ${ !inputState.isValid && inputState.isTouched &&
        'form-control--invalid' }` }
    >
      <label htmlFor={ props.id }>{ props.label }</label>
      { element }
      { !inputState.isValid && inputState.isTouched && <p>{ props.errorText }</p> }
    </div>
  );
};

export default Input;
