import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import './PlaceForm.scss';

import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import Input from '../../shared/components/FormElements/Input/Input';
import Button from '../../shared/components/FormElements/Button/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner/LoadingSpinner';
import ImageUpload from '../../shared/components/FormElements/ImageUpload/ImageUpload';
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from '../../shared/util/validators';

const NewPlace = props => {

  const { token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const initialInputs = {
    title: {
      value: '',
      isValid: false
    },
    description: {
      value: '',
      isValid: false
    },
    address: {
      value: '',
      isValid: false
    },
    image: {
      value: null,
      isValid: false
    }
  }

  const [formState, inputHandler] = useForm(initialInputs, false);

  const history = useHistory();

  const placeSubmitHandler = async e => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('title', formState.inputs.title.value);
      formData.append('description', formState.inputs.description.value);
      formData.append('address', formState.inputs.address.value);
      formData.append('image', formState.inputs.image.value);

      // sendRequest(url, method, body, headers)
      await sendRequest(
        `${ process.env.REACT_APP_BACKEND_URL }/api/places`,
        'POST',
        formData,
        { Authorization: 'Bearer ' + token }
      )
      // redirecting user
      history.push('/');

    } catch (err) { }
  }

  return (
    <React.Fragment>
      <ErrorModal error={ error } onClear={ clearError } />
      <form
        className="place-form"
        onSubmit={ placeSubmitHandler }>

        { isLoading && <LoadingSpinner asOverlay /> }

        <Input
          id="title"
          element="input"
          type="text"
          label="Title"
          onInput={ inputHandler }
          validators={ [VALIDATOR_REQUIRE()] }
          errorText="Please enter a valid title" />
        <Input
          id="description"
          element="textarea"
          label="Description"
          onInput={ inputHandler }
          validators={ [VALIDATOR_MINLENGTH(5)] }
          errorText="Please enter a valid description, at least 5 characters" />
        <Input
          id="address"
          element="input"
          label="Address"
          onInput={ inputHandler }
          validators={ [VALIDATOR_REQUIRE()] }
          errorText="Please enter a valid address" />
        <ImageUpload
          center
          id="image"
          onInput={ inputHandler }
          errorText="Please provide an image"
        />

        <Button type="submit" disabled={ !formState.isValid }>ADD PLACE</Button>
      </form>
    </React.Fragment>
  );
};

export default NewPlace;