import React from 'react';

import './UsersList.scss';

import UserItem from './UserItem';
import Card from '../../shared/components/UIElements/Card/Card';

const UsersList = props => {

  if (props.items.length === 0) {
    return <div className="center notification">
      <Card>No users found</Card>
    </div>
  }

  return (
    <ul className="users-list">
      { props.items.map(user => (
        <UserItem
          key={ user.id }
          id={ user.id }
          image={ user.image }
          name={ user.name }
          placeCount={ user.places.length } />
      )) }
    </ul>)
};

export default UsersList;