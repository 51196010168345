import React, { useState, useContext } from 'react';

import './PlaceItem.scss';

import Card from '../../shared/components/UIElements/Card/Card';
import Button from '../../shared/components/FormElements/Button/Button';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import Map from '../../shared/components/UIElements/Map/Map';
import ErrorModal from '../../shared/components/UIElements/ErrorModal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner/LoadingSpinner';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

const PlaceItem = props => {

  const { userId, token } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [showMap, setShowMap] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const openMapHandler = () => setShowMap(true);
  const closeMapHandler = () => setShowMap(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  }

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  }

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);

    try {
      await sendRequest(
        `${ process.env.REACT_APP_BACKEND_URL }/api/places/${ props.id }`,
        'DELETE',
        null,
        { Authorization: 'Bearer ' + token }
      );
      props.onDelete(props.id);
    } catch (err) { }
  }

  return (
    <React.Fragment>
      <ErrorModal error={ error } onClear={ clearError } />

      <Modal
        show={ showMap }
        onCancel={ closeMapHandler }
        header={ props.address }
        contentClass="place-item__modal-content"
        footerClass="place-item__modal-actions"
        footer={ <Button onClick={ closeMapHandler }>CLOSE</Button> }>
        <div className="map-container">
          <Map center={ props.coordinates } zoom={ 16 } />
        </div>
      </Modal>

      <Modal
        show={ showConfirmModal }
        onCancel={ cancelDeleteHandler }
        header="Are you sure?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={ cancelDeleteHandler }>CANCELL</Button>
            <Button danger onClick={ confirmDeleteHandler }>DELETE</Button>
          </React.Fragment>
        } >
        <p>Do you want to delete the place?</p>
      </Modal>

      <li className="place-item">
        <Card>
          { isLoading && <LoadingSpinner asOverlay /> }
          <div className="place-item__content">

            <div className="place-item__image">
              <img src={ `${ process.env.REACT_APP_BACKEND_URL }/${ props.image }` } alt={ props.title } />
            </div>
            <div className="place-item__info">
              <div className="place-item__title">
                <h3>{ props.title }</h3>
                <p>{ props.description }</p>
              </div>
              <div className="place-item__address">
                <p>{ props.address }</p>
              </div>
            </div>

          </div>


          <div className="place-item__actions">
            <Button inverse onClick={ openMapHandler }>VIEW ON MAP</Button>
            { userId === props.creatorId && (
              <Button to={ `/places/${ props.id }` }>EDIT</Button>
            ) }
            { userId === props.creatorId && (
              <Button danger onClick={ showDeleteWarningHandler }>DELETE</Button>
            ) }
          </div>
        </Card>
      </li>
    </React.Fragment>
  );
};

export default PlaceItem;